// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".page-3Mxrg{padding:0 16px;min-height:calc(100vh - 66px);display:flex;align-items:center;background:white}.container-10ag9{text-align:center;max-width:345px;margin:24px auto}.title-2bHAF{color:#000;font-family:\"GT-Walsheim\",sans-serif;font-size:34px;letter-spacing:-0.05em;margin-bottom:10px}.body-2svfo{color:#707070;font-family:\"Inter\",sans-serif;font-size:18px;line-height:140%;margin-bottom:28px}.storeButtons-G1CUd{display:flex;justify-content:center;margin-bottom:30px}.storeButtons-G1CUd :first-child{margin-right:8px}.storeButtons-G1CUd img{width:100%;height:100%}.update-2XM7S{color:#999;font-family:\"Inter\",sans-serif;font-size:14px;line-height:140%}\n", ""]);
// Exports
exports.locals = {
	"page": "page-3Mxrg",
	"container": "container-10ag9",
	"title": "title-2bHAF",
	"body": "body-2svfo",
	"storeButtons": "storeButtons-G1CUd",
	"update": "update-2XM7S"
};
module.exports = exports;
