import React from 'react';
import { translateElement } from 'instances/i18n';
import resetStyles from 'higher-order-components/resetStyles';
import styledModule from 'helpers/styledModule';
import AppStoreButton from 'components/buttons/AppStoreButton';
import PlayStoreButton from 'components/buttons/PlayStoreButton';
import CSS from './style.scss';

const Page = styledModule.div(CSS.page);
const Container = styledModule.div(CSS.container);
const StoreButtons = styledModule.div(CSS.storeButtons);
const Title = styledModule.h1(CSS.title);
const Body = styledModule.p(CSS.body);
const UpdateInfo = styledModule.p(CSS.update);

export const UniversalDeeplinkFallbackPage = () => (
  <Page>
    <Container>
      <Title>{translateElement('universal-deeplink.fallback-page.title')}</Title>
      <Body>{translateElement('universal-deeplink.fallback-page.body')}</Body>
      <StoreButtons>
        <AppStoreButton data-testid="app-store-button" />
        <PlayStoreButton data-testid="play-store-button" />
      </StoreButtons>
      <UpdateInfo>{translateElement('universal-deeplink.fallback-page.update-info')}</UpdateInfo>
    </Container>
  </Page>
);

export default resetStyles(UniversalDeeplinkFallbackPage);
